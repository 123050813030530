// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "r_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "r_fR d_fR d_bz d_bJ";
export var menuDesign6 = "r_sg d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "r_sh d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "r_sj d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "r_sk d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "r_sl d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "r_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "r_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "r_sm";
export var navbarItem = "r_nb d_bx";
export var navbarLogoItemWrapper = "r_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "r_sn d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "r_sp d_gd d_by d_Z d_bs";
export var burgerToggle = "r_sq d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "r_sr d_gd d_by d_Z d_bs";
export var burgerInput = "r_ss d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "r_st d_f3 d_w d_H";
export var burgerLine = "r_sv d_f1";
export var burgerMenuDesign6 = "r_sw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "r_sx d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "r_sy d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "r_sz d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "r_sB d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "r_sC d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "r_sD d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "r_sF d_bC d_bP";
export var compact = "r_sG";
export var navDivided = "r_sH";
export var staticBurger = "r_sJ";
export var menu = "r_sK";
export var navbarDividedLogo = "r_sL";
export var nav = "r_sM";
export var fixed = "r_sN";